<template>
  <v-layout app>
    <v-container fluid class="ma-4">
      <v-container class="ml-3">
      <v-expansion-panel class="hidden-md-and-up">
        <v-expansion-panel-content v-for="(position, index) in positions" :key="'position'+index">
        <template v-slot:header>
          <div>{{ position.role }}</div>
        </template>
        <v-card>
          <v-card-text>
            <p>ReVelUp {{position.role}} candidates must</p>
            <br/>
            <ul v-if="position.role==='Translator'">
              <li>Have satisfactory Korean aural and reading comprehension</li>
              <li>Be familiar with common Korean online language and regional dialects</li>
              <li>Have a good command of the English language</li>
              <li>Be willing to spend at least three hours a day during promotion periods</li>
            </ul>
            <ul v-if="position.role==='Dialogue Timer'">
              <li>have prior experience with timing on Aegisub</li>
              <li>know when to start/end the lines without understanding Korean</li>
              <li>be willing to work with translators and adjust to their styles</li>
              <li>be willing to spend at least three hours a day during promotion periods</li>
            </ul>
            <ul v-if="position.role==='Caption Timer'">
              <li>have prior experience with timing on Aegisub</li>
              <li>be willing to meet our caption timing standards</li>
              <li>know how to typeset with basic special effect tags</li>
              <li>be willing to spend at least three hours a day during promotion periods</li>
            </ul>
            <ul v-if="position.role==='Typesetter'">
              <li>have prior experience with typesetting on Aegisub</li>
              <li>be willing to meet our typesetting standards</li>
              <li>must know all the most commonly used special effect tags</li>
              <li>work with newly downloaded fonts</li>
              <li>be willing to spend at least three hours a day during promotion periods</li>
            </ul>
            <ul v-if="position.role==='QCer'">
              <li>have a good command in the English language</li>
              <li>be able to catch spelling, grammatical, and punctuation errors</li>
              <li>be able to rephrase awkward sentences</li>
              <li>be able to check for misapplied styles and color coding</li>
            </ul>
            <br/>
            <p v-if="position.open">Currently accepting applications</p>
            <p v-else>Application closed</p>
          </v-card-text>
        </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
      </v-container>
      <v-tabs color="primary-light" dark slider-color="secondary" class="hidden-sm-and-down pl-5">
        <v-tab v-for="(position, index) in positions" :key="'positionTab'+index" ripple>
          {{ position.role }}
        </v-tab>
        <v-tab-item v-for="(position, index) in positions" :key="'positionItem'+index">
          <v-card flat>
            <v-card-text >
              <p>ReVelUp {{position.role}} candidates must</p>
              <ul v-if="position.role==='Translator'">
                <li>Have satisfactory Korean aural and reading comprehension</li>
                <li>Be familiar with common Korean online language and regional dialects</li>
                <li>Have a good command of the English language</li>
                <li>Be willing to spend at least three hours a day during promotion periods</li>
              </ul>
              <ul v-if="position.role==='Dialogue Timer'">
                <li>have prior experience with timing on Aegisub</li>
                <li>know when to start/end the lines without understanding Korean</li>
                <li>be willing to work with translators and adjust to their styles</li>
                <li>be willing to spend at least three hours a day during promotion periods</li>
              </ul>
              <ul v-if="position.role==='Caption Timer'">
                <li>have prior experience with timing on Aegisub</li>
                <li>be willing to meet our caption timing standards</li>
                <li>know how to typeset with basic special effect tags</li>
                <li>be willing to spend at least three hours a day during promotion periods</li>
              </ul>
              <ul v-if="position.role==='Typesetter'">
                <li>have prior experience with typesetting on Aegisub</li>
                <li>be willing to meet our typesetting standards</li>
                <li>must know all the most commonly used special effect tags</li>
                <li>work with newly downloaded fonts</li>
                <li>be willing to spend at least three hours a day during promotion periods</li>
              </ul>
              <ul v-if="position.role==='QCer'">
                <li>have a good command in the English language</li>
                <li>be able to catch spelling/grammatical/punctuation errors</li>
                <li>be able to rephrase awkward sentences</li>
                <li>be able to check for misapplied styles and color coding</li>
              </ul>
              <br/>
              <p v-if="position.open">Currently accepting applications</p>
              <p v-else>Application closed</p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-container fluid class="ma-4">
        Applying for
        <v-layout row wrap>
          <v-flex xs12 class="mb-4">
            <v-layout row wrap>
              <v-flex xs12 sm6 md4 lg2 v-for="(position, index) in positions" :key="'positionCheck'+index">
                <v-checkbox color="secondary" hide-details :value="position.role" v-model="positionChoice">
                  <template v-slot:label color="secondary">
                    <div color="secondary">{{ position.role }}</div>
                  </template>
                </v-checkbox>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 lg4 :class="{'pr-3':  $vuetify.breakpoint.lgAndUp}"><v-text-field label="Name"  v-model="name" solo></v-text-field></v-flex>
          <v-flex xs12 lg4 :class="{'pr-3':  $vuetify.breakpoint.lgAndUp}"><v-text-field label="Email"  v-model="email" solo></v-text-field></v-flex>
          <v-flex xs12 lg4><v-text-field label="Twitter" solo v-model="twitter"></v-text-field></v-flex>
          <v-flex xs12><v-textarea label="Previous Experience" solo rows="2" v-model="prevxp"></v-textarea></v-flex>
          <v-flex xs12><v-textarea label="Why do you want to join ReVelUp Subs?" solo rows="4" v-model="whyJoin"></v-textarea></v-flex>
          <v-btn @click="submitApplication" :disabled="!(name !== '' && email != '' && whyJoin != '' && positionChoice.length != 0 && !sent)">Submit</v-btn>
        </v-layout>
      </v-container>
    </v-container>
    <v-snackbar v-model="snackbar" bottom :timeout="timeout" :color="snackColor"> 
      {{ snackText }}
      <v-btn color="red" flat @click="snackbar = false"><v-icon color="red" >close</v-icon></v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script>
import axios from 'axios'

export default {
  name: 'apply',
  data() {
    return {
      snackbar: false,
      timeout: 4000,
      snackColor: "",
      snackText: "",
      positions: [],
      positionChoice: [],
      drawer: null,
      name: "",
      email: "",
      twitter: "",
      prevxp: "",
      whyJoin: "",
      sent: false
    }
  },
  methods: {
    submitApplication: function () {
      this.sent = true
      var data = {
        name: this.name, 
        twitter: this.twitter,
        email: this.email, 
        previousExperience: this.prevxp, 
        whyJoin: this.whyJoin, 
        position: this.positionChoice.join()
      }

      axios({ url: process.env.VUE_APP_URL + "api/v1/applicants", method: "POST", data: data }).then(response => {
        this.snackText = "Application succesful"
        this.snackbar = true
        this.snackColor = "green"
      }).catch(() => {
        this.snackText = "Application failed, check all required fields are entered"
        this.snackbar = true
        this.snackColor = "red"
        this.sent = false
      })
    }
  },
  created () {
    axios({ url: process.env.VUE_APP_URL + "api/v1/positions", method: "GET"}).then(response => {
      this.positions = response.data.data
    })
  }
}
</script>

<style lang="scss">
  .v-navigation-drawer__border {
    width: 0px;
    display: none;
  }
  .menu-space {
    padding-left: 300px;
  }
  .v-icon, .v-input--checkbox {
    .v-label {
      color: var(--v-text-base) !important;
    }
    color: var(--v-text-base) !important;
  }

</style>
-