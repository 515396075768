<template>
  <v-layout app>
    <v-btn v-show="!loadedVideo.id" fab dark fixed class="hidden-lg-and-up ma-2">
      <v-icon dark @click.stop="drawer = !drawer">list</v-icon>
    </v-btn>
    <v-btn fab dark fixed right class="ma-5" v-show="loadedVideo.id">
      <v-icon dark @click="closeVideo()">close</v-icon>
    </v-btn>
    <v-container fluid grid-list-sm :class="{'menu-space':  $vuetify.breakpoint.lgAndUp}" class="mt-5" v-show="!loadedVideo.id" text-xs-center> 
      <v-layout row wrap>
        <v-flex xl2 md3 sm6 xs12 shrink v-for="(video, index) in videos" :key="'video'+index" @click="setVideo(index)">
          <v-img :src="video.videos.image" height="140" max-width="220" class="ma-auto"></v-img>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div class="video-title" v-on="on">{{video.videos.title}}</div>
              <div class="ma-auto video-subtitle">{{ video.videos.release_date }}</div>
            </template>
            <span>{{ video.videos.title }}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-container>
    <v-navigation-drawer v-model="drawer" clipped absolute class="pt-5 pl-1 pr-1" v-show="!loadedVideo.id">  
      <v-layout row wrap>
        <v-flex d-flex xs12 justify-center title>Sort</v-flex>
        <v-flex d-flex xs12 justify-center subheading>Member</v-flex>
        <v-flex d-flex xs12 md6 v-for="(data, index) in members" :key="'member'+index" text-xs-center>
          <div><v-btn small @click="setMember(data.id)" :class="{selected: selectedMembers.includes(data.id)}">{{data.name}}</v-btn></div>
        </v-flex>
        <v-flex d-flex xs12 justify-center subheading>Genre</v-flex>
        <v-select :items="genres" label="Genre" item-text="name" item-value="id" v-model="selectedGenre" class="ml-5 mr-5 mt-2" @change="getVideos"></v-select>
        <v-flex d-flex xs12 justify-center subheading>Era</v-flex>
        <v-select :items="eras" label="Era" item-text="name" item-value="id" v-model="selectedEra" class="ml-5 mr-5 mt-2" @change="getVideos"></v-select>
      </v-layout>
    </v-navigation-drawer>
    <v-container fluid fixed-tabs v-if="loadedVideo.id" :class="{'add-margin':  $vuetify.breakpoint.lgAndUp}">
      <v-tabs>
        <v-tab v-for="(source, index) in loadedVideo.video_sources" :key="'video-tab-'+index" v-model="selectedTab" :value="source.name">{{source.name}}</v-tab>
        <v-tab-item v-for="(source, index) in loadedVideo.video_sources" :key="'video-tab-'+index">
          <v-layout align-center justify-center>
            <iframe v-if="source.embed" :src="source.slug" width="640" height="360" frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowfullscreen="allowfullscreen"></iframe>
          </v-layout>
        </v-tab-item>
      </v-tabs>
      <v-divider></v-divider>
      <v-layout row wrap justify-center fill-height>
        <v-flex d-flex xs12 class="ma-2">{{ loadedVideo.title }}</v-flex>
        <v-flex d-flex xs12 class="ma-2">{{ loadedVideo.description }}</v-flex>
      </v-layout>
    </v-container>
  </v-layout>
</template>

<script>
import axios from 'axios'

export default {
  name: 'videos',
  data() {
    return {
      drawer: null,
      loadedVideo: {},
      genres: [],
      eras: [],
      members: [],
      selectedMembers: [],
      selectedGenre: "",
      selectedEra: "",
      videos: [],
      videoCount: 0
    }
  },
  created () { 
    axios({ url: process.env.VUE_APP_URL + "api/v1/videos/categories", method: "GET"}).then(response => {
      this.genres = response.data.data.filter(category => (category.categoryType === "Genres"))
      this.eras = response.data.data.filter(category => (category.categoryType === "Eras"))
      this.members = response.data.data.filter(category => (category.categoryType === "Members"))
    })
    this.getVideos()
  },
  methods: {
    getVideos: function () {
      var categories = this.selectedMembers
      if (this.selectedGenre !== "" || this.selectedEra !== "") {
        var moreCategories = []
        if (this.selectedGenre !== "") {
          moreCategories.push(this.selectedGenre)
        }
        if (this.selectedEra !== "") {
          moreCategories.push(this.selectedEra)
        }
        categories = categories.concat(moreCategories)
      }
      categories = categories.join()
      axios({ url: process.env.VUE_APP_URL + "api/v1/videos?categoryIds=" + categories, method: "GET"}).then(response => {
        this.videos = response.data.data.items
        this.videoCount = response.data.data.items
      })
    },
    setVideo: function (index) {
      this.loadedVideo = this.videos[index].videos
    },
    closeVideo: function () {
      this.loadedVideo = {}
    },
    setMember: function (id) {
      // Search for this member ID, if it exists remove it if it doesn't then add it
      var existsIndex = -1
      this.selectedMembers.forEach(function(e, i) {
        if (e === id) {
          existsIndex = i
        }
      })
      if (existsIndex > -1) {
        this.selectedMembers.splice(existsIndex,1)
      } else {
        this.selectedMembers.push(id)
      }
      this.getVideos()
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style language="scss">
  .menu-space {
    padding-left: 300px;
  }
  .add-margin {
    margin: 0 500px 0 500px;
  }
  .video-subtitle {
    width: 220px;
    text-align: left;  
  }
  .video-title {
    width: 220px;
    overflow: hidden;
    position: relative; 
    line-height: 1.4em; /* use this value to count block height */
    max-height: 2.8em;  /* max-height = line-height (2.8) * lines max number (2) */
    margin: auto;
    text-align: left;  
    padding-right: 0.5em;
  }
  /* create the ... */
  .video-title:before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0em;
    bottom: 0;
  }
  /* hide ... if we have text, which is less than or equal to max lines */
  .video-title:after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: white;
  }
  .selected {
    background-color: var(--v-primary-darken2) !important;
  }
</style>
