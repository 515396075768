import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex);

const state = {
  theme: {}
}

const getters = {
  theme: state => {
    return state.theme
  }
}

const actions = {
  async setTheme(context, theme) {
    context.commit('newTheme', theme)
  }
}

const mutations = {
  newTheme: (state, theme) => {
    state.theme = theme
  }
}

const plugins = [createPersistedState()]

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins,
})