<template>
  <v-app>
    <v-toolbar class="nav-toolbar">
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">Theme</v-btn>
          </template>
          <v-list> 
            <v-list-tile v-for="(theme, index) in themes" :key="'theme'+index" @click="changeTheme(theme)" :class="{selected: selectedName === theme.name}">{{theme.name}}</v-list-tile>
          </v-list>
        </v-menu>
        <v-btn flat to="/">Home</v-btn>
        <v-btn flat to="/videos">Videos</v-btn>
        <v-btn flat to="/team">Meet The Team</v-btn>
        <v-btn flat to="/apply">Apply</v-btn>
        <v-btn flat to="/donate">Donate</v-btn>
      </v-toolbar-items>
      <v-menu class="hidden-md-and-up">
        <v-toolbar-side-icon slot="activator"></v-toolbar-side-icon>
        <v-list> 
          <v-list-tile to="/">Home</v-list-tile>
          <v-list-tile to="/videos">Videos</v-list-tile>
          <v-list-tile to="/team">Meet The Team</v-list-tile>
          <v-list-tile to="/apply">Apply</v-list-tile>
          <v-list-tile to="/donate">Donate</v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-content class="site">
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
import vue from 'vue'
import Vuetify from 'vuetify'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'

export default {
  data() {
    return {
      /** hardcoded themes for easy testing */
      themes: [
        {
          name: 'Light',
          primary: '#1976D2',
          secondary: '#424242',
          accent: '#82B1FF',
          background: '#FAFAFA',
          text: '#FFFFFF',
        },
        {
          name: 'Dark',
          primary: '#424242',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          background: '#424242',
          text: '#FFFFFF',
        },
        {
          name: 'Ice Cream Cake',
          primary: '#212121',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          background: '#424242',
          text: '#FFFFFF'
        },
        {
          name: 'Bad Boy',
          primary: '#212121',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          background: '#424242',
          text: '#FFFFFF'
        },
        {
          name: 'Really Bad Boy',
          primary: '#212121',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          background: '#424242',
          text: '#FFFFFF'
        }
      ],
      selectedName: ""
    }
  },
  methods: {
    changeTheme: function (theme) {
      console.log(this.themes)
      this.setTheme(theme)
      this.applyTheme()
    },
    getThemeFromObject: function (theme) {
      return {
        primary: theme.primary,
        secondary: theme.secondary,
        accent: theme.accent,
        background: theme.background,
        text: theme.text
      }
    },
    applyTheme: function () {
      this.$vuetify.theme = this.getThemeFromObject(this.theme)
      this.selectedName = this.theme.name
    },
    ...mapActions(['setTheme'])
  },
  computed: mapGetters(['theme']),
  created: function () {
    axios({ url: process.env.VUE_APP_URL + "api/v1/themes", method: "GET"}).then(response => {
      // this.themes = response.data.data
    })
    this.applyTheme()
  }
}
</script>

<style lang="scss">
  //Style site background
  .v-content__wrap {
    color: var(--v-text-base) !important;
    background-color: var(--v-background-lighten1) !important;
  }
  //style navigation drawer
  .v-navigation-drawer{
    color: var(--v-text-base) !important;
    background-color: var(--v-background-lighten2) !important;
  }
  //style top toolbar
  .nav-toolbar {
    background-color: var(--v-background-base) !important;
    color: var(--v-text-base) !important;
    .v-btn,.v-input {
      color: var(--v-text-base) !important;
    }
  }
  // style dropdown menus
  .v-menu__content {
    background-color: var(--v-background-base) !important;
    .v-select-list { 
      background-color: var(--v-background-base) !important;
      .v-list {
        background-color: var(--v-background-lighten3) !important;
        color: var(--v-text-base) !important;
      }
      .v-list__tile__title{
        background-color: var(--v-background-lighten3) !important;
        color: var(--v-text-base) !important;
      }
    }
  }
  .v-select__selections {
    color: var(--v-text-base) !important;
  }
  //style buttons
  button {
    background-color: var(--v-primary-base) !important;
    color: var(--v-text-base) !important;
  }

  .v-menu__content,.v-list {
    background-color: var(--v-primary-base) !important;
    color: var(--v-text-base) !important;
    .selected {
      background-color: var(--v-primary-darken2) !important;
    }
  }
  .profile-card {
    background-color: var(--v-primary-lighten1) !important;
  }
</style>


