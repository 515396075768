<template>
  <v-layout row wrap justify-center>
    <v-flex d-flex xs12 text-xs-center>
      <h1>How we Started</h1>
    </v-flex>
    <v-flex d-flex xs10 md8 class="mb-4">
      ReVelUp Subs has been providing Red Velvet subbed videos since March of 2016. The team started with only four members. Their original plan was to keep the team small and close-knit, mostly for communication purposes. However, a growing demand for a reliable source for Red Velvet subbed videos led them to change their minds and recruit more members to form a team to expedite and improve the subbing process. The team has since thrived and is now the only Red Velvet subbing team fully active, serving as the sole provider of important subbing projects, such as “Level Up! Project.”
    </v-flex>
    <v-flex d-flex xs12 justify-center class="headline">Staff</v-flex>
    <v-flex v-for="(status, i) in staff" :key='"status"+i' :class="{ 'd-none': !status.members.length }" xs12 sm8>
      <v-layout row wrap justify-start class="pb-5">
        <v-flex d-flex xs12 justify-center class="subheading">{{ status.title }}</v-flex>
        <v-container fluid grid-list-xs>
          <v-layout row wrap>
            <v-flex v-for="(member, index) in status.members" :key='"staff"+index' xs12 sm6 md4 xl3 text-xs-center class="mb-3 member-box">
              <v-dialog v-model="member.dialog" width="500">
                <template v-slot:activator="{ on }"> 
                  <v-layout row wrap v-on="on" justify-center fill-height>
                    <v-hover>
                      <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 6 : 0}`" width="240" min-height="220" v class="pa-3 profile-card mr-2 ml-2">
                        <v-flex xs12 justify-center>
                          <v-avatar :size="avatars.size">
                            <img :src='member.avatar' />
                          </v-avatar>
                        </v-flex>
                        <v-flex xs12 justify-center class="font-weight-bold">{{ member.username }}</v-flex>
                        <v-flex xs12 justify-center class="font-weight-light">{{member.profile.roles}}</v-flex>
                      </v-card>
                    </v-hover>
                  </v-layout>
                </template>
                <v-card>
                  <v-card-title class="headline grey lighten-3" primary-title>{{ member.username }}</v-card-title>
                  <v-card-text>
                    <v-avatar :size="avatars.size/1.4" style="float: right" class="mr-3">
                      <img :src='member.avatar' />
                    </v-avatar>
                    <div class="pt-2">
                      <div><label class="pr-1">Name:</label><span class="font-weight-light">{{ member.profile.name }}</span></div>
                      <div><label class="pr-1">Gender:</label><span class="font-weight-light">{{ member.profile.gender }}</span></div>
                      <div><label class="pr-1">Age:</label><span class="font-weight-light">{{ member.profile.age }}</span></div>
                      <div><label class="pr-1">Horoscope:</label><span class="font-weight-light">{{ member.profile.horoscope }}</span></div>
                      <div><label class="pr-1">Ethnicity:</label><span class="font-weight-light">{{ member.profile.ethnicity }}</span></div>
                      <div><label class="pr-1">Residence:</label><span class="font-weight-light">{{ member.profile.residence }}</span></div>
                      <div><label class="pr-1">When and how did you become a fan?:</label><span class="font-weight-light"> {{ member.profile.whenFan }}</span></div>
                      <div><label class="pr-1">Why did you decide to work with RVUS?:</label><span class="font-weight-light"> {{ member.profile.whyWork }}</span></div>
                      <div><label class="pr-1">Bias:</label><span class="font-weight-light">{{ member.profile.bias }}</span></div>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="member.dialog = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-container>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios'

export default {
  name: 'home',
  data() {
    return {
      staff: [
        {
          title: "Full Time",
          members: []
        },
        {
          title: "Part Time",
          members: []
        },
        {
          title: "Inactive",
          members: []
        },
        {
          title: "Specialized",
          members: []
        },
        {
          title: "Retired",
          members: []
        }
      ],
      avatars: {
        size: 150,
        tile: false
      }
    }
  },
  created () {
      axios({ url: process.env.VUE_APP_URL + "api/v1/team", method: "GET"}).then(response => {
        //Filter staff by status and assign to correct position in staff array
        this.staff[0].members = response.data.data.filter(member => (member.dialog = false, member.profile.status === "Full Time"))
        this.staff[2].members = response.data.data.filter(member => (member.dialog = false, member.profile.status === "Inactive"))
        this.staff[1].members = response.data.data.filter(member => (member.dialog = false, member.profile.status === "Part Time"))
        this.staff[3].members = response.data.data.filter(member => (member.dialog = false, member.profile.status === "Specialized"))
        this.staff[4].members = response.data.data.filter(member => (member.dialog = false, member.profile.status === "Retired"))
      })
  }
}
</script>

<style lang="scss">
  .profile-card:hover {
    cursor: pointer;
  }
</style>
