<template>
  <div class="home-video-wrapper">
    <youtube ref="youtube" video-id="" :player-vars="playerVars" @ready="ready" @ended="ended" class="home-landing-video"></youtube>
    <!-- <video muted="muted" preload="auto" loop="true" autoplay="true">
       <source type="video/mp4" src="https://revelupsubs.com/wp-content/uploads/2018/11/rbbwebsitebackgroundslowercrop.mp4">
     </video> -->
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      playerVars: {
        modestbranding: 3,
        cc_load_policy: 0,
        listType: 'playlist',
        list: 'PLQCakH1gpYY8XQCN0DZ668XGZ-m7WUI7M',
        loop: 1,
        controls: 0,
        showinfo: 0,
        autoplay: 1,
        iv_load_policy: 3,
        mute: 1,
        rel: 0,
      }
    }
  },
  methods: {
    ready() {
      this.$refs.youtube.player.playVideo()
      this.$refs.youtube.player.setLoop(true) // loop playlists
    },
    ended() {
      // this.$refs.youtube.player.seekTo(0)
      // this.$refs.youtube.player.playVideo()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .home-video-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 1;
    &:before {
      content: ' ';
      background: url(../assets/home-vid-overlay.png) repeat left top scroll;
      background-color: rgba(#000, .36);
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 2;
    }
  }
  .home-landing-video {
    position: absolute;
    left: 50%;
    pointer-events: none;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  @media (max-aspect-ratio: 16/9) {
    .home-landing-video {
      // Scaling up height to try and hide black bars.
      width: calc(((100vh * 1.4) * 16) / 9);
      height: calc(100vh * 1.4);
    }
  }
  @media (min-aspect-ratio: 16/9) {
    .home-landing-video {
      width: (100vw * 1.4);
      height: calc(((100vw * 1.4) * 9) / 16);
    }
  }
</style>
