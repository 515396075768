import Vue from 'vue'
import './plugins/vuetify'
import VueRouter from 'vue-router'
import VueYoutube from 'vue-youtube'
import store from './store'
import App from './App.vue'
import home from './views/home'
import apply from './views/apply'
import donate from './views/donate'
import team from './views/team'
import videos from './views/videos'

Vue.use(VueRouter)
Vue.use(VueYoutube)

const routes = [
  { path: '/', component: home },
  { path: '/apply', component: apply },
  { path: '/donate', component: donate },
  { path: '/team', component: team },
  { path: '/videos', component: videos }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
